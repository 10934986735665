@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  padding: 0;
  margin: 0;
  font-family: 'Quicksand', sans-serif;
}

.App{
  height: 100vh;
  width:  100vw;
}

.wrapper{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.imgcontain > img{
  height: 235px;
  position: relative;
  -webkit-animation-name: slideInLeft;
          animation-name: slideInLeft;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  transition: 1s ease all;
}

@-webkit-keyframes slideInLeft {
  from {
    left: 300px;
  }
  to {
    left: 0px;
  }
}

@keyframes slideInLeft {
  from {
    left: 300px;
  }
  to {
    left: 0px;
  }
}

.title{
  height: 100%;
  line-height: 235px;
  -webkit-animation-name: slideInRight;
          animation-name: slideInRight;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  transition: 1s ease all;
  position: relative;
  font-weight: 900;
}

@-webkit-keyframes slideInRight {
  from {
    right: 300px;

  }
  to {
    right: 0px;

  }
}

@keyframes slideInRight {
  from {
    right: 300px;

  }
  to {
    right: 0px;

  }
}

.textcontain{
  overflow: hidden;
  position: relative;
  height: 235px;
  width: 350px;
  text-align: center;
}


.imgcontain{
  overflow: hidden;
  width: 350px;
  text-align: left;
}

.textcontain::after{
  content: '';
  position: absolute;
  height: 235px;
  width: 2.5px;
  background: #231F20;
  display: block;
  top: 0;
  left: 0;
  line-height: 100%;

}
